export const m = {
   //菜单栏
   cop: 'GUANGDONG FUNGENE Co., Ltd',
   product: 'Product',
   theBrand: 'Story for the brand',
   contactUs: 'Contact us',
   languageText:'English',
   shop:'Mall',
   enText:"English",
   chText:"中文",
   jpText:"日本語",
   aboutFungene: "Fungene",
   story: "Brand story",
   home: "Home page",
   mine: "Mine",
   xf:"AROMA DIFFUSER",

   //品牌故事
   brand: 'Fungene, Chinese brand of new lifestyle, adhere to the concept of sustainable design, combining original design, technology and environmental protection. It offers creative and quality life experience to those of you who know life better. It advocates life aesthetics of imagination. We hope that by changing the design of the product and interaction, to help you have high quality life and more comfortable experience.',
   brand01: 'Fungene, Chinese brand of new lifestyle, adhere to the concept of sustainable',
   brand02: 'design, combining original design, technology and environmental protection.',
   brand03: 'It offers creative and quality life experience to those of you who know life better.',
   brand04: 'It advocates life aesthetics of imagination. We hope that by changing the design of the product',
   brand05: 'and interaction, to help you have high quality life and more comfortable experience',

   //联系我们
   contact: 'Contact us',
   company: 'Guangdong Fungene Technolody co.,ltd',
   address: 'Address:No.1, 3rd Floor, Block C, Lanxiang Building, Fengxing Road, Gujian Village, Daliang Street, Shunde District , Foshan City, Guangdong Province, China',
   holine: 'Customer Service Hotline : 4001331518',
   salesHotline: 'Sales Hotline : David +86 13921060980, Sandra +86 13060784883',
   email: 'Official E-mail address :service@fungene.cc',
   scantext0: 'Quickly scan to see the applet',
   scantext1: 'For more information',

    //产品
    mainH5:'Intelligent Hand-Washer',
   //  mainMarket:'Stay tuned for the upcoming',
    mainMarket:'From RMB 399',
    mainBook:'Buy >',

    mainTextW0:'All changes is miracle after deep consideratio.',
    mainTextW1:'This is a hand-wash machine, with extreme performance, original Design, and',
    mainTextW2:'Excellent manufacturing process. Thus, it builds health defense line',
    mainTextW3:'For you, let you away from the threat of bacteria and viruses',

    mainTextM01:'All changes is miracle after deep consideratio.',
    mainTextM02:'This is a hand-wash machine, with extreme performance, original Design, and excellent manufacturing process. Thus, it builds health defense line for you, let you away from the threat of bacteria and viruses',

    index01:'Ultra-long standby time',
    index02:'90-day endurance',
    index03:'Friendly interaction',
    index04:'Interesting emoji interface',
    index05:'Pick up the induction',
    index06:'Prevent from liquid by mistake',
    index07:'Mute motor',
    index08:'Selected motor, durable and quiet',
    index09:'Wake up when close',
    index10:'All for you',
    index11:'Metal case',
    index12:'More textured experience',
    index13:'Remind when lack of liquid',
    index14:'Remind to replace when liquid insufficient',
    index15:'Originality design',
    index16:'Grace to your interest',

    acme01:'No matter what',
    acme02:'Do it to the perfection',
    acme03:'We have been always making great efforts',
    acme04:'Just to walk with you through years',
    acme05:'Simple lines, reserved design and neutral color, showing clean and perfect image',
    acme06:'Exquisite electroplating process brings elegant visual effect and delicate and comfortable feeling',
    acme07:'Carefully crafted beauty, like a work of art, has both appearance and meaning.',
    acme08:'Simple lines, reserved design and neutral color, showing clean and perfect image. Exquisite electroplating process brings elegant visual effect and delicate and comfortable feeling. Carefully crafted beauty, like a work of art, has both appearance and meaning.',

    intelligent01:'Intelligent',
    intelligent02:'Automatic induction of liquid discharge',
    intelligent03:'Smart living within reach',
    intelligent04:'Touch unnecessary, hand to reach out, H5 protects you from bacterial and viral threats',
    intelligent05:'Build healthy defense line together with you',
    intelligent06:'Touch unnecessary',
    intelligent07:'Hand to reach out',
    intelligent08:'H5 protects you from bacterial and viral threats',

    standby01:'Extra long standby',
    standby02:'Endurance of 20,000 times ',
    standby03:'5000mAh large capacity battery+original battery management scheme',
    standby04:'Endurance up to 20,000 times without frequent charging',
    standby05:'5000mAh large capacity battery',
    standby06:'Original battery management scheme',
    standby07:'Endurance up to 20,000 times',
    standby08:'Without frequent charging',
    standby09:'(*the data came from the Vigni lab and were calculated at 30 sessions per day)',

    close01:'Proximity induction',
    close02:'Come a little closer',
    close03:'Let me see what you feel',
    close04:'I feel alive when you come to me. When you come closer, you will find that my eyes are filled with ',
    close05:'joy,because of you. Come on, let me take you with a pure hand to touch the smile of your child and ',
    close06:'the mouth of your lover, to feel the tenderness and beauty of the world',
    close07:'I feel alive when you come to me. When you come closer, you will find that my eyes are filled with joy, because of you. Come on, let me take you with a pure hand to touch the smile of your child and the mouth of your lover, to feel the tenderness and beauty of the world.',
  
    interaction01:'Induction',
    interaction02:'Abnormal induction of shaking',
    interaction03:'Built-in induction chip, sensitive when picked up or shaken',
    interaction04:'Prevent fluid by mistake effectively',
    interaction05:'Built-in induction chip',
    interaction06:'Accurate determination of fluid deficiency',
    interaction07:'Built-in induction chip',
    interaction08:'Sensitive when picked up or shaken',
    interaction09:'Induction of lack of fluid',
    interaction10:'Working condition under control',

    color01:'Color',
    color02:'Elegant in style',
    color03:'Remarkable',
    color04:'Calm as dark at night, elegant as bronze when late autumn, designated color customized as well',
    color05:'Deep Black',
    color06:'Bronze',
    color07:'Customized Color',

    inside01:'Inside',
    inside02:'The subtle shows the style',
    inside03:'Universe determined by fraction of a penny',
    inside04:'Seeking for the subtle to ensure that the fuselage assembly without glue and screws, and match exactly',

    listen01:'Please listen to me',
    listen02:'The story behind the birth of H5',
    listen03:'Good design needs to be put the experience of life into creativity designers of the H5 is dad in the 80s, prize winner of Red Dot & IF award. When the outbreak arrived, he was very anxious, because his son of 3 years is in the rebellious phase, and touch wherever it is dirty.Washing hands became a difficult thing. Reasoning things out did not work. In the end, with cry of the child and the impatience of the parents, washing hands finished always. Therefore, he wanted to design a washing program for adults and children to accept with joy.',
    listen04:'Then H5 was born! With  innocence heart, wonderful life with artistic conception was created. Concise and artistic design language, combined with interesting and clever way of interaction, H5 is not only a washing machine, but also a carrier in real world of imagination. It is like an old friend, day, to accompany with you and escort your health every day.',

    parameters01:'The basic parameters',
    parameters02:'Product model: H5',
    parameters03:'Rated voltage: DC 4V', 
    parameters04:'Rated power: 2.5W',
    parameters05:'Main material: metal, ABS+PC',
    parameters06:'Executive standard: GB4706.1',

   //底栏
   footText: 'Copyright © 2022 FUNGENE ',
   record:'粤ICP备2020126461号',



   //官网第二版
   video: 'Video Tutorials',
   vip:  'VIP',
   oil: 'OIL',
   hometop: 'EXPLORE TECHNOLOGY AESTHETICS NEW BOUNDARY',
   home1: 'QUALITY LIFE',
   home2: 'ART AESTHETICS',
   home3: 'ORIGINAL PROCESS',
   home4: 'HIGH END INTELLIGENCE',
   home5: 'ORIGINAL CRAFTSMANSHIP AND LUXURIOUS ARTICLE CAN STAND THE TEST OF TIME.',

   home6: 'Self-developed metal atomization head effectively',
   home7: 'avoid erosion by aroma oil,',
   home8: 'and matching with two-fluid air atomization technology,',
   home9: 'the atomization effect reaches nanometer level, ',
   home10: 'covering 1500m³indoors area.',

   home11: 'Device body adopts premium aluminum material, ',
   home12:'the device base is globally well-chosen rare premium crystal , ',
   home13:'well matched with colorful ambient light under unlimited light-dimmer mode.',


   home14: 'Product is designed by new prominent design team,',
   home15: 'product has both excellence performance and premium quality,',
   home16: ' outstanding aroma diffusing technology,',
   home17: 'aroma fragrance flows everywhere to improve indoor atmosphere and ease emotion, ',
   home18: 'bring user high life.',
   
   home19: 'Smart control, comfortably connect to modern life, ',
   home20: 'experience humanized various requirements.',
   
   middle1: 'LIFE·SPACE·TECHNOLOGY·BEAUTY',
   middle2: 'Simplified without any redundance,',
   middle3: 'keeping balance between “aesthetics” and “ functionality”,',
   middle4: 'which is paradox but also unified,',
   middle5: 'well explained extremely simplified aesthetic artwork,',
   middle6: 'bring users with happy life experience.',

   art1: 'ARTWORK SHOW ROOM, OFFICE, LIBRARY, LEISURE AREA, SHOPPING MALL, BOUTIQUE, ',
   art2: 'STUDIO, ENTERTAINMENT VENUES,',
   art3: 'LIVING ROOM, BEDROOM, KITCHEN, RESTROOM(...)',


   aboutUs: 'About us',
   aboutDes: '「FUN + GENE」is core of FUNGENE brand driving us continuously break through boundary of innovation and aesthetics creating more vitality product. ',

   a1: 'FUNGENE is born in 2015 and focusing on high end smart technological small home appliance',
   a2: '「FUN + GENE」is core of FUNGENE brand driving us continuously break through boundary of innovation and aesthetics,',
   a3: 'Creating more vitality product and original craftsmanship and luxurious article can stand the test of time.',
   a4: 'FUNGENE sticks to the people-oriented concept and adopt extremely simplified design style,',
   a5: 'adhering to craftsmanship spirit as foot stone for branding development, continuously exploiting new design expression, striving to make quality and details perfect,',
   a6: 'deliver premium product to users to express FUNGENE BRAND’s sincerity.',

   a7: 'Environmental- friendly, renewable, sustainable.... as core concept to guide design, production, material, process and innovation,',
   a8: 'FUNGENE think about the relationship between human and nature throughout to try to make human in harmony with nature.',
   
   a9: 'Technology is going through innovation, functionality is upgrading, times changing, the unchanged is,',
   a10: 'Human is our consistent object of concern, digitization self research and development,interactive design, multiple functionality application......',
   a11: 'The nature is exploring consumers’ requirement to provide consumers ideal life experience.',

   a12: 'To realize “ simplified beauty” is not so simple, exploit potential possibility from tradition material To acquire premium and rare raw material from nature, meticulously improve product process, ',
   a13: 'continuously upgrade details and improve quality,',
   a14: 'In this case, we can make durable, long-lasting original craftsmanship and luxurious product.',

   bDes1: 'JIANGSU',
   bDes2: 'Building 2, No.4 Jianye Road, Tianmu Lake Industrial Park, Liyang City,',
   bDes3: 'Jiangsu Province, China',
   bDes4: 'FUNGENE | HEADQUARTERS',

   bDes5: 'GUANGDONG',
   bDes6: 'No.41, Feng Xiang road, Xin Jiao community, Da Liang street, Shun De area, Fo Shan city,',
   bDes7: 'Guangdong province, China',
   bDes8: 'R&D CENTER | INNOVATION | TECHNOLOGY',

   bDes9: 'SHANGHAI',
   bDes10: 'Building 5, Tong Chuang 717 industrial park, No.1538, Yan An West Road , ',
   bDes11: 'Chang Ning Area, Shanghai city, China',
   bDes12: 'R&D CENTER | MARKETING | SALES',



   cDes1: 'AFTER SALES SERVICE',
   cDes2: 'FUNGENE attaches great importance to users’ experience and satisfaction, hence, ',
   cDes3: 'we establish a veteran and professional team with hospitality and friendly communication skills, by sincere, ',
   cDes4: 'patient and meticulous attitude to improve high quality service to ensure users’',
   cDes5: 'to have comfortable shopping experience and products usage experience.',
   cDes6: 'AFTER SALES SERVICE HOTLINE：',
   cDes7: ' E-MAIL：',

   

   dDes1: 'SOCIAL MEDIA',
   dDes2: 'We sincerely invite you to follow FUNGENE social media platform account and interact with us, ',
   dDes3: 'so that we can know your requirements and feedback to accelerate us to improve our products and service quality. ',
   dDes4: 'We will post latest news related to our branding and product periodically through our social media account, ',
   dDes5: 'and we shall share funny and interesting content such as our stories, activities ect.',
   dDes6: 'You can not only get latest new feeds related to branding but also join in our interactive activities to gain variety of rewards and gifts.',
   dDes7: '',
   dDes8: 'XIAOHONGSHU OFFICIAL ACCOUNT/ WECHAT ACCOUNT：FUNGENE梵吉尼  |  TAOBAO FLAGSHIP STORE： FUNGENE梵吉尼旗舰店',

   dDes9: 'APP/SMALL APPLICATION',
   dDes10: 'Search “FUNGENE” through APP store and other application store to download FUNGENE APP, ',
   dDes11: 'or you can use small application through wechat by searching “ FUNGENE smart control”',
   dDes12: 'TO MAKE ROOM SPACE MORE INTELLIGENT, LET OUR LIFE BECOMING MORE ELEGANT.',



// A8
a8Title: 'A8 AROMA DIFFUSER',
color: 'COLOR',
buy: 'BUY',
a8T: 'TASTE OF A8',
a8I: 'INTELLECT A8',
a8H: 'HONOR OF A8',
a8TDes: 'FUNGENE A8 aroma diffuser is intelligent and focusing on High end scenarios, It break through traditional design, fully presenting excellence, luxurious and premium quality, outstanding aroma diffusing and make living room quality more higher than ever. ',
a8IDe: 'We believe modern living and innovative intelligence can be tightly connected, FUNGENE self-developed smart ecosystem can be connected by APP or small application and satisfy personal tailor-made requirement freely and realize multiple intelligent controls and enjoy happy life experience.',
a8HDes: 'Simplified art expression, integration smooth line, neat and clean outline matching with craftsmanship process made FUNGENE A8 aroma diffuser and obtain 2023 iF reward and prize. ',
a8Type: 'CATEGORY: SMART HOME APPLIANCE.',


a8DeviceDes1: 'SIMPLE AESTHETICS EXPRESS AND TOP NOTCH MANUFACTURING PROCESS,',
a8DeviceDes2: 'COMBINING WITH OUTSTANDING AROMA DIFFUSING TECHNOLOGY, ',
a8DeviceDes3: 'PRESENTING INCOMPARABLE AROMA FRAGRANCE ART. ',

a8SquDes1: 'TWO-FLUID MODEL TECHNOLOGY MATCHING WITH ',
a8SquDes2: 'NANOMETER ATOMIZATION EFFECT',
a8SquDes3: 'Fragrance fully fill room, ',
a8SquDes4: 'enjoy top-level luxurious elegance of aroma experience. ',
 
appDes1:'HUMAN-ORIENTED ',
appDes2:'INNOVATIVE SMART DESIGN',
appDes3:'To realize multiple remote controls by FUNGENE APP and small program application, ',
appDes4:'smart aroma concentration adjustment to enable aroma oil to fill in room with best status. ',
appDes5:'FUNGENE A8 aroma diffuser maximumly display its convenience and performance through FUNGENE innovative intelligence ecosystem. ',

appDes6:'MAGNET ATTACHED STRUCTURE ',
appDes7:'AIR-INLET GRILLE IS SIMPLE AND ELEGANT.',
appDes8:'Washable sieve matches with diamond lathed air-inlet grille is conveniently assembled to ',
appDes9:'block harmful molecule to enter and display extraordinary functionality. ',


a8Scene1: 'UNIQUE SILENCE AIR PUMP TO KEEP QUIET ',
a8Scene2: 'AND PEACE TIME.',
a8Scene3: 'RECONSTRUCT HOME ELECTRICAL ',
a8Scene4: 'APPLIANCE AESTHETICS AND FEEL TASTE OF LIFE.',
a8Scene5: 'Aroma fragrance experience',


// H5
h5Title: 'H5 SOAP DISPENSER',
h5Price: '￥699',
h5T: 'TASTE OF H5',
h5I: 'INTELLECT H5',
h5H: 'HONOR OF H5',
h5TDes: 'FUNGENE H5 is a intelligent automatic soap dispenser which integrates with innovation, quality and art aesthetics elements, it has interactive smiles display which is originated by FUNGENE company, it brings warm and quality life feeling in corner of somewhere in room. ',
h5IDes: 'FUNEGENE H5 soap dispenser is made of exquisite and premium aluminium material, it is made through multiple craftsmanship processes such as polish, oxidation, electroplating etc., which made FUNGENE H5 like a piece of artwork.',
h5HDes: 'Flexible interaction design, simple aesthetics style, wonderful luster and color, all elements make FUNGENE H5 obtain 2022 iF design award. ',

h5Des1 : 'CRAFTSMANSHIP MAKES PREMIUM ARTWORK, ',
h5Des2 : 'ENJOY UNIQUENESS, ',
h5Des3 : 'CREATIVE HANDING WASHING EXPERIENCE.',

h5DeviceDes1: 'FLAWLESS PERFORMANCE, EVEN AC MOTOR,',
h5DeviceDes2: 'DURABLE AND LASTING',
h5DeviceDes3: 'Low noise and safety, stable rotation, more durable and lasting,',
h5DeviceDes4: ' tested by lab for multiple times that FUNGENE H5 soap dispenser ',
h5DeviceDes5: 'can last for 100000 times working for normal use. ',

h5AppDes1: 'INDUSTRIAL LEVEL CHIP,NEAR ZONE RADAR SENSING, ',
h5AppDes2: 'LOW POWER CONSUMPTION, INTELLIGENT EXPERIENCE.',
h5AppDes3: 'Micro-expression interactive experience is originated in this industry, smart shift adjustment, the soap dispenser will be activated when users approaching, ',
h5AppDes4: 'and soap dispenser will stand-by when users walk away, our soap dispenser make hand-washing more funny and interesting. ',

h5AppDes5:'CREATIVE SELF-CLEANING MODE, ',
h5AppDes6:'CLEAN RESIDUE.',
h5AppDes7:'Effectively clean residue inside of plastic pipe and bottle, ',
h5AppDes8:'big bottleneck and volume, convenient to change hand soap sanitizer. ',

h5ColorDes1: 'COLOR CAN BE CUSTOM AND A LOT OF COLORS FOR OPTIONS,',
h5ColorDes2: 'MAKE LIFE MORE COLORFUL.',
h5ColorDes3: 'RECONSTRUCT HOME ELECTRICAL ',
h5ColorDes4: 'APPLIANCE AESTHETICS AND FEEL TASTE OF LIFE.',
h5ColorDes5: 'Aroma fragrance experience',


// S5
s5Title: 'S5 AROMA DIFFUSER',
s5Price: '¥ 2180',
s5T: 'TASTE OF S5',
s5I: 'INTELLIGEN S5',
s5P: 'INTELLECT S5',
s5TDes: 'FUNGENE S5 intelligent aroma diffuser on behalf of simplified aesthetics being good match for aroma oil. Exquisite FUNGENE S5 aroma diffuser is very suitable for small space use such as on the table and in small room and it adds art elements for room and let users feel completely new aroma fragrance experience. ',
s5IDes: 'We believe modern living and innovative intelligence can be tightly connected, FUNGENE aroma diffuser self-developed smart ecosystem can be connected by APP or small application and satisfy personal tailor-made requirement freely and realize multiple intelligent controls and enjoy happy life experience. ',
s5PDes: 'FUNGENE S5 integrate aesthetics and performance and combining with extraordinary aroma fragrance diffusing technology making the best effect of fragrance diffusing.It is good choice for space aroma art.',

s5DeviceDes1: 'AROMA DIFFUSER BODY ADOPT PREMIUM ALUMINIUM MATERIAL ',
s5DeviceDes2: 'AND CRAFTSMANSHIP TOP NOTCH PROCESS. ',
s5DeviceDes3: 'OUR AROMA DIFFUSER MAKE THINGS SIMPLE AND EASY, FIND EXQUISITE FROM DETAILS,',
s5DeviceDes4: 'ENRICHED WITH COLORS OPTIONS ',
s5DeviceDes5: 'TO MATCH VARIED SPACE SCENARIOS. ',

s5SquarDes1: 'NANOMETER ATOMIZATION,FINE AROMA MOLECULE FILLED IN ROOM. ',
s5SquarDes2: 'Aroma diffuser is self-defined its concentration value, ',
s5SquarDes3: 'users can find its proper comfortable way to experience fragrance and make users relaxed and joyful physically and mentally.',
s5SquarDes4: 'resting in the breath,relaxing into life find your inner peace',


s5APPDes1: 'BLUETOOTH AND WIFI DOUBLE MODES FOR CONNECTION',
s5APPDes2: 'AND FIX IT BY ONE-BUTTON.',
s5APPDes3: 'Download FUNGENE APP, one-button management intelligent device, the aroma diffusing can be controlled remotely, ',
s5APPDes4: 'self-define scenarios and mode, real time aroma oil consumption feedback.',


s5APPDes5: 'AIRCRAFT LEVEL ALUMINIUM ALLOY ATOMIZATION CHAMBER,',
s5APPDes6: 'ANTIOXIDANT AND ANTI-CORROSION.',
s5APPDes7: 'Details decide quality, choose premium aluminium material, fine texture, watersplash proof, make aroma oil into fine molecule fragrance in room space.',
s5APPDes8: 'EXCELLENT PERFORMANCE, ',
s5APPDes9: 'START COMPLETELY NEW AROMA FRAGRANCE EXPERIENCE.',
s5APPDes10: 'RECONSTRUCT HOME ELECTRICAL ',
s5APPDes11: 'APPLIANCE AESTHETICS AND FEEL TASTE OF LIFE.',


// 精油
oilTitle: 'ESSENTIAL OIL',
oilSmell: 'SCENT STORY',
oilDiao: 'FRAGRANCE NOTES',
oilSX: 'ATTRIGUTE',
oilSL: 'SERIES',
oilType: 'CATEGORY:  FRAGRANCE',

oilDes1: 'IT IS THE GENEROUS GIFT OF NATURE THAT GIVES ',
oilDes2: 'THIS UNIQUE FRAGRANCE WORLD.',
oilDes3: 'Elegant, calm, quiet, hot, and free, ',
oilDes4: 'use your senses to perceive the length and beauty of time ',
oilDes5: 'and the intertwining of your heart and breath to convey the power of life.',

oilSmellDes1: 'THE SMELL OF NATURE',
oilSmellDes2: 'FRAGRANCE.',

oilSmellDes3: 'Suddenly, like a ray of morning light piercing the mist among bamboo forests, mountains, and rivers.',
oilSmellDes4: 'A blooming camellia Foreseeing the coming of another colorful spring.',
oilSmellDes5: 'A glacier that existed 150 million years ago melted;  the eternal wind carries its breath.',
oilSmellDes6: 'The pages are silent, But he recounted the ancient fable.',
oilSmellDes7: 'The air condenses in the heat wave, crush a green orange with your bare hands, and the scorching sun dims.',
oilSmellDes8: 'Travel through the wind from Glorious，Leave the scent of grass and trees in the wild.',
oilSmellDes9: 'Cascading forests cover the birds,Sacred secrets remain hidden.',
oilSmellDes10: 'The breeze on the lake side doesn`t say a word,Stir the beautiful dreams.',
oilSmellDes11: '"Ka-ching",It`s the lonely whisper of fallen leaves,It`s a footnote to an autumnal poem.',
oilSmellDes12: 'Sitting around the fire,The elder can`t bear to stop,This moment never ends.',

// 关于我们
about1: 'FUNGENE, a gift company specializing in high-end scenes, was founded in 2015 as an emerging lifestyle brand in China. Adhering to the concept of sustainable design, Vangini combines originality, technology, and environmental protection to provide creative, high-quality, and high-quality living experiences for those who understand life better. Vangini advocates for a "life aesthetic that satisfies imagination." We hope to help you achieve a better quality of life and a more comfortable experience by changing the design and interaction of our products.',
about2: 'The company adheres to original research and development of smart home products. Like smart hand sanitizers (H5) and fragrance diffusers. The company integrates independent research and development with sales. Currently, it has more than 70 employees from the headquarters in Liyang, Jiangsu and the Foshan branch. It has a complete organizational structure and an excellent R&D team. Once the company`s products are launched, they have gained a good reputation and sales have been steadily increasing.',
about3: 'The company adheres to the values of "customer first, innovation as the priority, cooperation and progress, and continuous improvement", and builds an elite team. We look forward to you, who are both virtuous and talented, becoming our partner, working together to win the future!',

about4: 'Vision',
about5: 'Becoming a high-end scenario oriented gift technology company',
about6: 'Brand concept',
about7: 'Excellent Creation, Excellent Enjoyment, Concentric Thoughts',
about8: 'Values',
about9: 'Customer first innovation is to work together to improve',
about10: 'Product strategy',
about11: 'Innovation-driven experience exceptional quality control',
about12: 'Technical element',
about13: 'Stable and easy to use innovation',



// story
story1: 'We use timeless design techniques and excellent craftsmanship,',
story2: 'Leave amazing moments in your life.',
story3: 'Fungene is committed to excellent interaction, exquisite craftsmanship, and temperature technology',
story4: 'In this era of fast fashion',
story5: 'Polish every detail carefully to enhance the taste of space.',
story6: 'We want to have Vandini in every quality space.',


// video
videoDes1: 'Unpacking Video',
videoDes2: 'A8',
videoDes3: 'H5',
videoDes4: 'S5',

videoDes5: 'Promotional Video',
videoDes6: 'A8',
videoDes7: 'H5',
videoDes8: 'S5',

videoDes9: 'Tutorial video',
videoDes10: 'H5 Change fluid volume',
videoDes11: 'H5 Cleaning mode',
videoDes12: 'H5 expression switch',
videoDes13: 'H5 Replace the liquid',


noBatter:'L6 AROMA DIFFUSER',
l6:'FRAGRANCE DIFFUSER',
media: 'Media',
fun: 'FUNGENE',
funTitle: 'atisfying the aesthetic of imagined life',

























}